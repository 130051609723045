.timelineItem {
  @apply flex-1 mx-2 p-3 text-center;
  min-width: 140px;
}

.timeline {
  @apply bg-green-400 h-full w-full;
  background: linear-gradient(
    90deg,
    rgba(244, 207, 105, 1),
    rgba(247, 152, 0, 1),
    rgba(75, 194, 254, 1),
    rgba(21, 83, 175, 1)
  );
  width: 0%;
}

.timelineMask {
  @apply absolute;
  left: calc(100% + 2px);
  height: calc(100% + 10px);
  top: -6px;
}

.timelineCheckContainer {
  @apply relative inline-block mx-auto rounded-full;
  border: 14px solid white;
  z-index: 2;
}

@keyframes grow {
  100% {
    width: 100%;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .timeline {
    @apply w-full;
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  .timeline.animate {
    animation: grow 1300ms linear forwards;
  }
}
