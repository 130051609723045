.animatedCheck {
  @apply p-1 flex justify-center items-center rounded-full text-white border border-white bg-transparent shadow-md invisible;
  z-index: 1;
}

.animatedCheck.md {
  @apply p-1.5;
}

.animatedCheck.lg {
  @apply p-2;
}

@media screen and (prefers-reduced-motion: reduce) {
  .animatedCheck.green {
    @apply bg-green-400 border-green-400 visible;
  }

  .animatedCheck.primary {
    @apply bg-primary border-primary;
  }

  .animatedCheck.transparent {
    @apply bg-transparent border-transparent;
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  .animatedCheck.animate.green {
    animation: check 1s linear forwards;
  }

  .animatedCheck.animate.primary {
    animation: checkPrimary 1s linear forwards;
  }

  .animatedCheck.animate.transparent {
    animation: checkTransparent 1s linear forwards;
  }

  .animatedCheck.animate.green::before {
    @apply absolute h-full w-full bg-green-400 rounded-full opacity-40;
    content: "";
    z-index: -1;
    animation: fade 1s linear forwards;
  }

  .animatedCheck.animate.primary::before {
    @apply bg-primary;
  }

  .animatedCheck.delay1 {
    animation-delay: 200ms !important;
  }

  .animatedCheck.delay2 {
    animation-delay: 400ms !important;
  }

  .animatedCheck.delay3 {
    animation-delay: 600ms !important;
  }

  .animatedCheck.delay4 {
    animation-delay: 800ms !important;
  }

  .animatedCheck.delay5 {
    animation-delay: 1000ms !important;
  }

  .animatedCheck.delay6 {
    animation-delay: 1200ms !important;
  }
}

@keyframes check {
  0% {
    @apply visible;
    transform: scale(0.8);
  }
  20% {
    transform: scale(1.2);
    @apply bg-green-400 border-green-400;
  }
  50% {
    transform: scale(0.9);
  }
  60%,
  100% {
    @apply visible;
    transform: scale(1);
    @apply bg-green-400 border-green-400;
  }
}

@keyframes checkPrimary {
  0% {
    @apply visible;
    transform: scale(0.8);
  }
  20% {
    transform: scale(1.2);
    @apply bg-primary border-primary;
  }
  50% {
    transform: scale(0.9);
  }
  60%,
  100% {
    @apply visible;
    transform: scale(1);
    @apply bg-primary border-primary;
  }
}

@keyframes checkTransparent {
  0% {
    @apply visible;
    transform: scale(0.8);
  }
  20% {
    transform: scale(1.2);
    @apply bg-transparent border-transparent;
  }
  50% {
    transform: scale(0.9);
  }
  60%,
  100% {
    @apply visible;
    transform: scale(1);
    @apply bg-transparent border-transparent;
  }
}

@keyframes fade {
  50%,
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
