.scrollContainer {
  @apply overflow-x-auto m-2;

  -ms-overflow-style: none; /* IE */
  overflow: -moz-scrollbars-none; /* Firefox */
  scrollbar-width: none; /* Firefox 64 */
  -webkit-overflow-scrolling: touch;
}

.scrollContainer::-webkit-scrollbar {
  display: none;
}

.scrollButton {
  @apply absolute h-full flex items-center justify-center p-1 md:p-4 text-gray-700 bg-white;
  z-index: 3;
}

.scrollButtonLeft {
  composes: scrollButton;
  @apply top-0 -left-full;
  transition: left 300ms ease-in-out;
  box-shadow: 15px 0px 25px #fff;
}

.scrollButtonLeft.active {
  @apply top-0 left-0;
}

.scrollButtonRight {
  composes: scrollButton;
  @apply top-0 -right-full;
  transition: right 500ms ease-in-out;
  box-shadow: -15px 0px 25px #fff;
}

.scrollButtonRight.active {
  @apply top-0 right-0;
}
